import React, { useEffect, useRef } from "react"
import "animate.css/animate.min.css"
import $ from "jquery"
import VanillaTilt from "vanilla-tilt"
import * as fabric from "fabric" 

const AccountantsDashboardWidget = () => {
  const canvasRef = useRef(null)
  const initialImages = [
    { url: "/images/base-dashboard-top.png", options: { left: 230, top: 0, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 20 }, animateOutOptions: {top: 100}, animateDuration: 2000 },
    { url: "/images/base-applications.png", options: { left: 230, top: -50, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 80 }, animateOutOptions: {top: 200}, animateDuration: 2000 },
    { url: "/images/base-announcements.png", options: { left: 230, top: 120, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 440 }, animateOutOptions: {top: 550}, animateDuration: 2000 },
    { url: "/images/base-assigned-tasks.png", options: { left: 230, top: 220, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 630 }, animateOutOptions: {top: 700}, animateDuration: 2000 },
    { url: "/images/base-clients.png", options: { left: 875, top: 0, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 75 }, animateOutOptions: {top: 220}, animateDuration: 2000 },
    { url: "/images/base-notes.png", options: { left: 880, top: 20, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 210 }, animateOutOptions: {top: 350}, animateDuration: 2000 },
    { url: "/images/base-task-completion.png", options: { left: 885, top:110, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 455 }, animateOutOptions: {top: 550}, animateDuration: 2000 },
  ];

  const baseComponents = useRef([])

  useEffect(() => {

    VanillaTilt.init(document.querySelectorAll(".tilt-container"), {
      max: 15,
      glare: false,
      scale: 1,
      speed: 8000,
      perspective: 2000,
    })
    
    // Notes click function
    $(".show-announcements-btn").on("click", () => handleShowAnnouncements(canvas))
    $(".create-announcement-btn").on("click", () => handleCreateAnnouncementSequence(canvas))

    // Leads click function
    $(".show-profile-btn").on("click", () => handleShowProfile(canvas))

    // Clients click function
    $(".show-teams-btn").on("click", () => handleShowTeams(canvas))
    $(".create-team-btn").on("click", () => handleCreateTeamsSequence(canvas))

    // Tasks click function
    $(".show-tasks-btn").on("click", () => handleShowTasks(canvas))
    $(".create-task-btn").on("click", () => handleCreateTaskSequence(canvas))


    const canvas = new fabric.Canvas(canvasRef.current)
    
    // add base images
    if (canvas) {
      addSidebarImageToCanvas(canvas, "/images/base-without-shadow.png")
      initialImages.forEach(img =>
        addImage( canvas, img.url, img.options, img.animateOptions, img.animateOutOptions, img.animateDuration )
      )
    }


    return () => {
      canvas.dispose()
      $(".show-announcements-btn").off("click")
      $(".create-announcement-btn").off("click")
      $(".show-profile-btn").off("click")
      $(".show-teams-btn").off("click")
      $(".create-team-btn").off("click")
      $(".show-tasks-btn").off("click")
      $(".create-task-btn").off("click")
    }
  }, [])


  // Add Base Sidebar Dasbhord Image function
  const addSidebarImageToCanvas = (canvas, imgPath) => {
    const imgElement = new Image()
    imgElement.src = imgPath
    imgElement.onload = () => {
      const imgInstance = new fabric.Image(imgElement, {
        selectable: false,
        scaleX: 1,
        scaleY: 1,
      })
      canvas.add(imgInstance)
    }
  }

  

  // Animate Fabric Property function
  function animateProperty( canvas, obj, property, endValue, duration, easingFunction, onComplete ) {
    fabric.util.animate({
      startValue: obj.get(property),
      endValue: endValue,
      duration: duration,
      easing: easingFunction,
      onChange: function (value) {
        obj.set(property, value)
        canvas.requestRenderAll()
      },
      onComplete: onComplete,
    })
  }

  // Fabric add image function
  const addImage = ( canvas, url, options, animateOptions, animateOutOptions, animateDuration ) => {
    const imgElement = new Image()
    imgElement.src = url
    imgElement.onload = () => {
      const imgInstance = new fabric.Image(imgElement, options)
      canvas.add(imgInstance)

      // Initial opacity set to 0
      imgInstance.set("opacity", 0)
      canvas.renderAll()

      // In Animations
      animateProperty( canvas, imgInstance, "opacity", 1, animateDuration, fabric.util.ease.easeOutBounce )
      animateProperty( canvas, imgInstance, "top", animateOptions.top, animateDuration, fabric.util.ease.easeOutQuint )

      baseComponents.current.push({
        img: imgInstance,
        animateOutOptions: animateOutOptions.top,
      })
    }
  }

  // fabric remove base components function
  const removeBaseComponents = canvas => {
    baseComponents.current.forEach(obj => {
      // Out Animations
      animateProperty( canvas, obj.img, "opacity", 0, 2000, fabric.util.ease.easeOutQuint )
      animateProperty( canvas, obj.img, "top", obj.animateOutOptions, 2000, fabric.util.ease.easeOutQuint )
    })
    setTimeout(() => {
      baseComponents.current.forEach(obj => {
        canvas.remove(obj.img)
      })
      baseComponents.current = []
    }, 1000)
  }

  
  // Function to animate the Inner screen sequence
  function addInnerCreateItemScreensSequence(canvas, images, finalImages) {
    $(".canvas-animated-btn").fadeOut().removeClass("animated-moved");
  
    // Adding shadow image
    const shadowImgElement = new Image();
    shadowImgElement.src = "/images/base-shadow.png";
    shadowImgElement.onload = () => {
      const shadowImgInstance = new fabric.Image(shadowImgElement, { left: 0, top: 0, scaleX: 1, scaleY: 1, selectable: false });
      let basShadowImage = shadowImgInstance;
      canvas.add(shadowImgInstance);
      canvas.renderAll(); // Render shadow image
  
      // Adding flow images
      let timeout = 0;
      let finalImagesTimeout = 500;
      let addedImage = null;
  
      images.forEach((img, index) => {
        setTimeout(() => {
          const imgElement = new Image();
          imgElement.src = img.url;
          imgElement.onload = () => {

            if (addedImage) {
              canvas.remove(addedImage);
            }

            const imgInstance = new fabric.Image(imgElement, img.options);
            addedImage = imgInstance;
            canvas.add(imgInstance);
            canvas.renderAll(); // Render image on canvas
  
            // Handle animations if present
            if (img.animate) {
              const { gif, gifDuration, gifEndY, left, scaleX, scaleY, duration } = img.animate;
              if(gif !== undefined){
                window.setTimeout(function(){
                  animateGifImage(canvas, -30, gifEndY, gifDuration, imgInstance)
                }, duration)
              }
              if (left !== undefined) {
                animateProperty(canvas, imgInstance, 'left', left, duration, fabric.util.ease.easeOutQuint);
              }
              if (scaleX !== undefined) {
                animateProperty(canvas, imgInstance, 'scaleX', scaleX, duration, fabric.util.ease.easeOutQuint);
              }
              if (scaleY !== undefined) {
                animateProperty(canvas, imgInstance, 'scaleY', scaleY, duration, fabric.util.ease.easeOutQuint);
              }
            }
          };
        }, timeout);
  
        timeout += img.animate ? img.animate.timeout : 1000;
        finalImagesTimeout += img.animate ? img.animate.timeout : 1000;
      });
  
      // Removing previous images and adding final images
      window.setTimeout(() => {
        if (addedImage) canvas.remove(addedImage);
        if (basShadowImage) canvas.remove(basShadowImage);
        removeBaseComponents(canvas);
        canvas.renderAll();
  
        window.setTimeout(() => {
          finalImages.forEach(img => addImage(canvas, img.url, img.options, img.animateOptions, img.animateOutOptions, img.animateDuration));
  
          window.setTimeout(() => {
            $(".canvas-animated-btn").fadeIn();
          }, 1000);

        }, 1000);
      }, finalImagesTimeout);
    };
  }


  // Gif Image Animation function
  function animateGifImage(canvas, startY, endY, duration, img) {

    var startTime = Date.now();
  
    function draw() {
      var currentTime = Date.now();
      var elapsedTime = currentTime - startTime;
      var progress = elapsedTime / duration;
  
      if (progress < 1) {
        // Calculate current position and opacity based on progress
        var currentY = startY + (endY - startY) * progress;
  
        // Update image position and opacity
        img.set({
          top: currentY,
        });
  
        // Render canvas
        canvas.renderAll();
  
        // Request next frame
        requestAnimationFrame(draw);
      } else {
        // Animation complete
        img.set({
          top: endY,
        });
        canvas.renderAll();
      }
    }
  
    // Start animation
    draw();
  }


  /********************************************************
   * Announcement Screen starts here
  ********************************************************/ 

  const handleShowAnnouncements = canvas => {
    addSidebarImageToCanvas(canvas, "/images/base-without-shadow--announcements.png")
    $(".canvas-animated-btn").fadeOut().removeClass("animated-moved")
    $(".create-announcement-btn").addClass("animated-moved")
    removeBaseComponents(canvas)

    setTimeout(() => {
      const formImages = [
      { url: "/images/announcements-screen-image-1.png", options: { left: 230, top: 0, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 20 }, animateOutOptions: {top: 100}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image-2.png", options: { left: 235, top: 30, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 160 }, animateOutOptions: {top: 300}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image-3.png", options: { left: 220, top: 60, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 180 }, animateOutOptions: {top: 500}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image-4.png", options: { left: 220, top: 90, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 350 }, animateOutOptions: {top: 700}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image-5.png", options: { left: 220, top: 90, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 520 }, animateOutOptions: {top: 700}, animateDuration: 1000 },
    ];
      formImages.forEach(img =>
        addImage( canvas, img.url, img.options, img.animateOptions, img.animateOutOptions, img.animateDuration )
      )
    }, 1000)
  }

  const handleCreateAnnouncementSequence = canvas => {
    const images = [
      { url: "/images/create-announcement-screen-1.png", options: { left: 950, top: 0, scaleX: 1, scaleY: 1, selectable: false }, animate: { left: 810, duration: 1500, timeout: 2000 } },
      { url: "/images/create-announcement-screen-2.png", options: { left: 810, top: 0, scaleX: 1, scaleY: 1, selectable: false }, animate: { duration: 1500, timeout: 2000 } },
      { url: "/images/create-announcement-screen-3.png", options: { left: 820, top: 30, scaleX: 1.3, scaleY: 1.3, selectable: false }, animate: { left: 780, top: 30, scaleX: 1, scaleY: 1, duration: 1000, timeout: 1000 } }
    ];
  
    const finalImages = [
      { url: "/images/announcements-screen-image-1.png", options: { left: 230, top: 0, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 20 }, animateOutOptions: {top: 100}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image-2.png", options: { left: 235, top: 30, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 160 }, animateOutOptions: {top: 300}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image--success-1.png", options: { left: 220, top: 60, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 180 }, animateOutOptions: {top: 500}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image-4.png", options: { left: 220, top: 90, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 350 }, animateOutOptions: {top: 700}, animateDuration: 1000 },
      { url: "/images/announcements-screen-image-5.png", options: { left: 220, top: 90, scaleX: 1, scaleY: 1, opacity: 0, selectable: false }, animateOptions: { top: 520 }, animateOutOptions: {top: 700}, animateDuration: 1000 },
    ];

    addInnerCreateItemScreensSequence(canvas, images, finalImages);
  }

  /********************************************************
   * Announcement Screen starts here
  ********************************************************/ 



  /********************************************************
   * Profile Screen starts here
  ********************************************************/ 

  const handleShowProfile = canvas => {
    addSidebarImageToCanvas(canvas, "/images/base-without-shadow--profile.png")
    $(".canvas-animated-btn").fadeOut().removeClass("animated-moved")
    $(".create-lead-btn").addClass("animated-moved")
    removeBaseComponents(canvas)

    setTimeout(() => {
      const formImages = [
        { url: "/images/profile-screen-image-1.png", options: { left: 240, top: 0, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 30 }, animateOutOptions: {top: 100}, animateDuration: 1000},
        { url: "/images/profile-screen-image-2.png", options: { left: 230, top: 28, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 80 }, animateOutOptions: {top: 200}, animateDuration: 1000 },
        { url: "/images/profile-screen-image-3.png", options: { left: 230, top: 60, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 230 }, animateOutOptions: {top: 400}, animateDuration: 1000 },
        { url: "/images/profile-screen-image-4.png", options: { left: 225, top: 60, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 400 }, animateOutOptions: {top: 500}, animateDuration: 1000 },
        { url: "/images/profile-screen-image-5.png", options: { left: 230, top: 60, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 460 }, animateOutOptions: {top: 600}, animateDuration: 1000 },
    ];
      formImages.forEach(img =>
        addImage( canvas, img.url, img.options, img.animateOptions, img.animateOutOptions, img.animateDuration )
      )
    }, 1000)

    window.setTimeout(() => {
      $(".canvas-animated-btn").fadeIn();
    }, 2000);
  }

  /********************************************************
   * Profile Screen starts here
  ********************************************************/ 


  /********************************************************
   * Teams Screen starts here
  ********************************************************/ 

  const handleShowTeams = canvas => {
    addSidebarImageToCanvas(canvas, "/images/base-without-shadow--teams.png")
    $(".canvas-animated-btn").fadeOut().removeClass("animated-moved")
    $(".create-team-btn").addClass("animated-moved")
    removeBaseComponents(canvas)

    setTimeout(() => {
      const formImages = [
        { url: "/images/teams-screen-image-1.png", options: { left: 230, top: -30, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 30 }, animateOutOptions: {top: 100}, animateDuration: 1000},
        { url: "/images/teams-screen-image-2.png", options: { left: 230, top: 28, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 200 }, animateOutOptions: {top: 280}, animateDuration: 1000 },
    ];
      formImages.forEach(img =>
        addImage( canvas, img.url, img.options, img.animateOptions, img.animateOutOptions, img.animateDuration )
      )
    }, 1000)
  }

  const handleCreateTeamsSequence = canvas => {
    const images = [
      { url: "/images/create-team-screen-1.png", options: { left: 840, top: 0, selectable: false }, animate: { left: 785, duration: 2000, timeout: 2000 } },
      { url: "/images/create-team-screen-2.png", options: { left: 785, top: 0, selectable: false }, animate: { left: 785, duration: 2000, timeout: 2000 } },
      { url: "/images/create-team-screen-3.png", options: { left: 820, top: 30, scaleX: 1.3, scaleY: 1.3, selectable: false }, animate: { left: 780, top: 30, scaleX: 1, scaleY: 1, duration: 1000, timeout: 2000 } },
      { url: "/images/create-team-screen-4.png", options: { left: 450, top: 220, scaleX: 1.2, scaleY: 1.2, selectable: false }, animate: { left: 500, top: 350, scaleX: 1, scaleY: 1, duration: 1000, timeout: 1000 } }
    ];
  
    const finalImages = [
      { url: "/images/teams-screen-image-1.png", options: { left: 230, top: -30, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 30 }, animateOutOptions: {top: 100}, animateDuration: 1000},
      { url: "/images/create-team-screen--success-1.png", options: { left: 230, top: 28, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 200 }, animateOutOptions: {top: 280}, animateDuration: 1000 },
    ];

    addInnerCreateItemScreensSequence(canvas, images, finalImages);
  }

  /********************************************************
   * Teams Screen starts here
  ********************************************************/




  /********************************************************
   * Tasks Screen starts here
  ********************************************************/ 

  const handleShowTasks = canvas => {
    addSidebarImageToCanvas(canvas, "/images/base-without-shadow--tasks.png")
    $(".canvas-animated-btn").fadeOut().removeClass("animated-moved")
    $(".create-task-btn").addClass("animated-moved")
    removeBaseComponents(canvas)

    setTimeout(() => {
      const formImages = [
        { url: "/images/tasks-screen-image-1.png", options: { left: 230, top: -30, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 30 }, animateOutOptions: {top: 100}, animateDuration: 1000},
        { url: "/images/tasks-screen-image-2.png", options: { left: 230, top: 0, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 162 }, animateOutOptions: {top: 300}, animateDuration: 1000},
        { url: "/images/tasks-screen-image-3.png", options: { left: 230, top: 50, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 310 }, animateOutOptions: {top: 450}, animateDuration: 1000},
    ];
      formImages.forEach(img =>
        addImage( canvas, img.url, img.options, img.animateOptions, img.animateOutOptions, img.animateDuration )
      )
    }, 1000)
  }

  const handleCreateTaskSequence = canvas => {
    const images = [
      { url: "/images/create-task-screen-1.png", options: { left: 840, top: -30, selectable: false }, animate: { gif: true, gifDuration: 10000, gifEndY: -925, left: 250, duration: 2000, timeout: 13500 } },
      { url: "/images/create-task-screen-2.png", options: { left: 820, top: 30, scaleX: 1.3, scaleY: 1.3, selectable: false }, animate: { left: 780, top: 30, scaleX: 1, scaleY: 1, duration: 1000, timeout: 1000 } }
    ];
  
    const finalImages = [
      { url: "/images/tasks-screen-image-1.png", options: { left: 230, top: -30, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 30 }, animateOutOptions: {top: 100}, animateDuration: 1000},
      { url: "/images/tasks-screen-image-2.png", options: { left: 230, top: 0, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 162 }, animateOutOptions: {top: 300}, animateDuration: 1000},
      { url: "/images/tasks-screen-image--success-1.png", options: { left: 200, top: 50, scaleX: 1, scaleY: 1, selectable: false }, animateOptions: { top: 310 }, animateOutOptions: {top: 450}, animateDuration: 1000},
    ];

    addInnerCreateItemScreensSequence(canvas, images, finalImages);
  }

  /********************************************************
   * Tasks Screen starts here
  ********************************************************/


  return (
    // <div className="tilt-container relative bottom-[125px] md:!bottom-[350px] mb-[-150px] md:!mb-[-350px]">

    <div className="tilt-container">
      <canvas
        ref={canvasRef}
        width="1200"
        height="675"
        className="canvas-element"
      ></canvas>
      <div className="canvas-animated-btn show-announcements-btn">
        <img src="/images/announcements-button-icon.svg" width="25px" alt="icon" />
        <span>Announcements</span>
      </div>
      <div className="canvas-animated-btn create-announcement-btn">
        <img src="/images/note-button-icon.svg" width="25px" alt="icon" />
        <span>Add Announcement</span>
      </div>

      <div className="canvas-animated-btn show-profile-btn">
        <img src="/images/profile-button-icon.svg" width="25px" alt="icon" />
        <span>Profile</span>
      </div>

      <div className="canvas-animated-btn show-teams-btn">
        <img src="/images/team-button-icon.svg" width="25px" alt="icon" />
        <span>Teams</span>
      </div>
      <div className="canvas-animated-btn create-team-btn !max-w-[115px]">
        <img src="/images/client-button-icon.svg" width="25px" alt="icon" />
        <span>Invite Team Member</span>
      </div>
      
      <div className="canvas-animated-btn show-tasks-btn !left-[80%]">
        <img src="/images/task-button-icon.svg" width="25px" alt="icon" />
        <span>Tasks</span>
      </div>
      <div className="canvas-animated-btn create-task-btn">
        <img src="/images/task-button-icon.svg" width="25px" alt="icon" />
        <span>Add Task</span>
      </div>

    </div>
  )
}

export default AccountantsDashboardWidget
