import React from "react"
import Seo from "../components/seo"
import InnerPageHeroSection from "../components/common/InnerPageHeroSection"
import SolutionsSection from "../components/common/SolutionsSection"
import RoiCalculatorSection from "../components/pages/AccountantsPracticeManagement/RoiCalculatorSection"
import TestimonialsSection from "../components/common/TestimonialsSection"
import HappyCustomerSection from "../components/common/HappyCustomerSection"
import FeaturesSection from "../components/common/FeaturesSection"
import CaseStudySection from "../components/common/CaseStudySection"
import BusinessModelsWithFormSection from "../components/common/BusinessModelsWithFormSection"
import MobileAppLinksSection from "../components/common/MobileAppLinksSection"
import SecurityCardsSection from "../components/common/SecurityCardsSection"
import Layout from "../components/layout"
import {
  caseStudiesSection,
  faqs,
  featuresSection,
  happyCustomerSection,
  heroSection,
  problemSection,
  solutionsSection,
  testimonialSection,
} from "../Content/AccountantsPracticeManagement"
import FaqsSection from "../components/common/FaqsSection"
import { businessModelsWithFormSection, mobileAppLinksSection } from "../Content/Common"
import HorizontalCardsScrollSection from "../components/common/HorizontalCardsScrollSection"

const AccountantsPracticeManagement = () => {
  return (
    <Layout>
      <Seo
        title="Seamless Accounting Practice Management and Client Data Gathering"
        description={`Transform your accounting practice with Synkli. Simplify workflows, automate data management, and seamlessly gather client data for paperless accounting practice management.`}
      />

      <InnerPageHeroSection
        title={heroSection.title}
        description={heroSection.description}
        btns={heroSection.btns}
        widget={heroSection.widget}
      />

      <HorizontalCardsScrollSection
        title={problemSection.title}
        description={problemSection.description}
        topProblems={problemSection.topProblems}
        bottomProblems={problemSection.bottomProblems}
      />

      <SolutionsSection
        title={solutionsSection.title}
        description={solutionsSection.description}
        firstColumnSolutions={solutionsSection.firstColumnSolutions}
        secondColumnSolutions={solutionsSection.secondColumnSolutions}
        thirdColumnSolutions={solutionsSection.thirdColumnSolutions}
      />

      <RoiCalculatorSection />

      <FeaturesSection
        title={featuresSection.title}
        features={featuresSection.features}
      />

      <TestimonialsSection
        title={testimonialSection.title}
        description={testimonialSection.description}
      />

      <CaseStudySection
        title={caseStudiesSection.title}
        description={caseStudiesSection.description}
      />

      <HappyCustomerSection
        subTitle={happyCustomerSection.subTitle}
        mainTitle={happyCustomerSection.mainTitle}
        title={happyCustomerSection.title}
        description={happyCustomerSection.description}
      />

      <BusinessModelsWithFormSection
        title={businessModelsWithFormSection.title}
        description={businessModelsWithFormSection.description}
        formSubmitButtonText={
          businessModelsWithFormSection.formSubmitButtonText
        }
      />

      <FaqsSection faqs={faqs} />

      <MobileAppLinksSection
        title={mobileAppLinksSection.title}
        description={mobileAppLinksSection.description}
      />

      <SecurityCardsSection />
    </Layout>
  )
}

export default AccountantsPracticeManagement
